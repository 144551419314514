<template>
    <div>
        <vertical-form-table mode="view" v-bind="{ value, items }" hideTopDivider />
    </div>
</template>

<script>
import { initForm } from "@/assets/variables";
import { items } from "./items";

import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

export default {
    components: {
        VerticalFormTable,
    },
    props: {
        value: { type: Object, default: initForm },
    },
    data: () => ({
        items: [
            ...items.map((item) => ({
                ...item,
                cols: 6,
            })),
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep .v-sheet {
    background: transparent !important;
}
</style>
