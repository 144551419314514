import { attrs_input, rules } from "@/assets/variables";

export const items = [
    {
        key: "writer.name",
        term: "고객명",
        type: "text",
        rules: rules.name,
        required: true,
        placeholder: "고객명을 입력하세요.",
        ...attrs_input,
    },
    {
        key: "writer.phone",
        term: "연락처",
        type: "phone",
        rules: rules.phone,
        required: true,
        ...attrs_input,
    },
];
